import React, { useEffect, useState } from 'react';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import ElementButtons from './widgets/ElementButtons';
import ColorsPopover from '../popover/ColorsPopover';

const StageToolbar: React.FC = () => {
    const [color, setColor] = useState('#aabbcc');

    const {
        state,
        isEditing,
        getElementDataById,
    } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();

    const { current } = state;

    useEffect(() => {
        if (current.exists) {
            const data = getElementDataById(current.id);
            if (data && data.attributes.fill) {
                setColor(data.attributes.fill);
            }
            ;
        }
    }, [current]);

    const handleColorChange = (newColor: string) => {
        const data = getElementDataById(current.id);
        if (current.id && data && data.attributes) {
            const attributes = data.attributes;
            stateDispatch({
                type: "updateElement",
                payload: {
                    id: current.id,
                    attributes: {
                        ...attributes,
                        fill: newColor
                    }
                }
            })
            setColor(newColor);
        }
    }

    return (
        <div className="flex flex-col justify-between items-center mt-3 p-4 max-w-fit z-50 h-fit">
            <div className='flex-col justify-center items-center w-full'>
                {isEditing && <ColorsPopover direction='left' color={color} setColor={handleColorChange} />}
                <ElementButtons col />
            </div>
        </div>
    )
}

export default StageToolbar;
