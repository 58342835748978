import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { HexColorPicker } from 'react-colorful';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import MouseOverPopover from '../buttons/MouseOverPopover';

type ColorPopoverProps = {
	color: string;
	setColor: (value: string) => void;
	direction?: 'center' | 'left' | 'right';
}

type ColorBoxProps = {
	color: string;
	onClick: (value: string) => void;
}

type ColorListProps = {
	onClick: (value: string) => void;
}

const CustomButton = styled(Button)(({ theme }) => ({
	width: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	padding: '8px',
	borderRadius: '8px',
	transition: 'background-color 0.2s',
	cursor: 'pointer',
	marginBottom: '4px',
	boxShadow: "0px 2px 2px 0px #CCCCCC",
	backgroundColor: '#ffffff',
	'&:hover': {
		backgroundColor: '#fff',
	},
}));

export default function ColorsPopover({ color, setColor, direction = 'center' }: ColorPopoverProps) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event: React.MouseEvent<any>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<MouseOverPopover mainClass="mx-1" popoverText='Escolher cor'>
				<CustomButton
					aria-describedby="color-popover"
					onClick={handleClick}
				>
					<ColorBox color={color} onClick={() => null} />
					<ChevronDownIcon
						className={`${open ? '' : 'text-opacity-70'}
                  h-5 w-5 transition duration-150 ease-in-out group-hover:text-opacity-80 text-zinc-600 ml-auto`}
						aria-hidden="true"
					/>
				</CustomButton>
			</MouseOverPopover>
			<Popover
				id="color-popover"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: direction,
				}}
			>
				<div className={`relative grid gap-4 p-4 grid-cols-1 w-fit`}>
					<HexColorPicker color={color} onChange={setColor} />
					<ColorList onClick={setColor} />
					<input value={color} onChange={(e: any) => setColor(e.target.value)} placeholder="#aabbcc"
						className="w-full uppercase hover:border-zinc-300 border-2 text-center text-zinc-500 rounded-md" type="text" />
				</div>
			</Popover>
		</>
	);
}

function ColorList({ onClick }: ColorListProps) {
	return (
		<div className='flex flex-row space-x-2' >
			<ColorBox color="#000000" onClick={onClick} />
			<ColorBox color="#ffffff" onClick={onClick} />
			<ColorBox color="#ff0000" onClick={onClick} />
			<ColorBox color="#f4ff00" onClick={onClick} />
			<ColorBox color="#00ff18" onClick={onClick} />
			<ColorBox color="#0032ff" onClick={onClick} />
			<ColorBox color="#ff7b00" onClick={onClick} />
		</div>
	)
}

function ColorBox({ color, onClick }: ColorBoxProps) {
	return (
		<div onClick={() => onClick(color)}
			className={`w-5 h-5 rounded-md cursor-pointer border border-zinc-400`} style={{ backgroundColor: color }} ></div>
	)
}
