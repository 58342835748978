import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import React from 'react';

interface ArrowProps {
    onClick: () => void
}

const SidesController: React.FC = () => {
    const { state, getElementDataById } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();
    const { current } = state;

    const data = getElementDataById(current.id);
    const element = data?.attributes;

    const increaseSides = () => {
        if (element && element.sides) {
            const increase = element.sides + 2;
            if (increase <= 15) {
                stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: element.id,
                        attributes: {
                            ...element,
                            sides: increase
                        }
                    }
                })
            }
        }
    }

    const decreaseSides = () => {
        if (element && element.sides) {
            const decrease = element.sides - 2;
            if (decrease >= 5) {
                stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: element.id,
                        attributes: {
                            ...element,
                            sides: decrease
                        }
                    }
                })
            }
        }
    }

    return (
        <div className={`p-2 bg-white shadow-md rounded-lg flex items-center no-text-highlight`}>
            <div className="mr-2">
                Número de lados:
            </div>
            <div className="mr-2">
                {element?.sides || 0}
            </div>
            <div className="flex flex-col">
                <ArrowUp onClick={increaseSides} />
                <ArrowDown onClick={decreaseSides} />
            </div>
        </div>
    )
}

export default SidesController;

const ArrowUp: React.FC<ArrowProps> = ({ onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={onClick}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
    </svg>
);

const ArrowDown: React.FC<ArrowProps> = ({ onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={onClick}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
    </svg>
);
