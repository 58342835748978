import React, { useState, useEffect, useRef } from "react";
import BooleanInputRowWrapper from "@/components/partials/editor/TopBar/BooleanInputRowWrapper";
import { EntityID } from "@/types/silverstripe";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { useMember } from "@/contexts/member/MemberContext";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useQueue } from "@/contexts/queue/QueueContext";
import { useTranslation } from "react-i18next";
import Api from "@/api/Api";
import EditorSidebar from "@/components/editor/EditorSidebar";
import EditorTopBar from "@/components/editor/EditorTopBar";
import dynamic from "next/dynamic";
import { Orientation } from "@/types/canvas";
import StageToolbar from "@/components/editor/StageToolbar";
import DynamicTextModal from "@/components/editor/modals/DynamicTextModal";
import SystemInfo from "@/helpers/SystemInfo";
import Konva from "konva";
import useScreenDimensions from "@/hooks/useScreenDimensions";
import SidesController from "./partials/SidesController";
import BorderRadiusController from "./partials/BorderRadiusController";
import { useSaveTemplate } from "@/contexts/modals/saveTemplate/SaveTemplateContext";
import MouseOverPopover from "@/components/buttons/MouseOverPopover";
import SpliterIcon from "@/components/svg/SpliterIcon";
import ComponentProvider from "@/components/editor/ComponentProvider";
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import Notify from "@/helpers/Notify";

const Canvas = dynamic(() => import('@/components/canvas/Canvas'), {
	ssr: false,
});

const __BG_ANIMATED_GRADIENT__ = "transform-gpu transition-all duration-[600ms] bg-gradient-to-r to-dark-orange via-orange-400 from-dark-orange bg-size-200 bg-pos-0 hover:bg-pos-100 group";

// @TODO: find out why the appplication is re-rendering the componente
const CanvasSidebar = () => {
	const { showCanvasModal: isOpen, setShowCanvasModal } = useModals();

	const toggleSidebar = () => setShowCanvasModal(!isOpen);

	const { isLoginModalVisible } = useMember();
	const { t: c } = useTranslation('canvas.translation');

	const { addToQueue } = useQueue();

	const { isLargeScreen, isSmallScreen } = useScreenDimensions();

	const { setMarketList, setSelectedConstraint } = useSaveTemplate();

	const { bannerProducts } = useCanvasProducts();

	const {
		state,
		isGeneratingImage,
		lineID,
		setLineID
	} = useCanvas();

	const { stateDispatch } = useCanvasDispatch();

	const {
		editor,
		templateId,
		current
	} = state;

	const { member } = useMember();

	const stageRef = useRef<Konva.Stage>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const [templateOwnerID, setTemplateOwnerID] = useState<EntityID>(null);

	const paddingTop = calculatePaddingTop(state.editor.orientation);

	const api = new Api('templates');

	useEffect(() => {
		const urlCompany = new Api('company');
		const urlTemplate = new Api('templates');
		(async () => {
			const companies: any = [];
			const companyRes = await urlCompany.post({ hash: member.hash }, 'gml');
			if (templateId) {
				const templateRes = await urlTemplate.post({ hash: member.hash, templateID: templateId }, 'grl');
				templateRes.success ? setSelectedConstraint(templateRes.data.template) : setSelectedConstraint([]);
			} else {
				setSelectedConstraint([]);
			}
			if (companyRes.success) {
				const list = companyRes.data.companyList;
				list.forEach((companyWithMarkets: any) => {
					// Extrair dados da empresa (company) e mercados (markets)
					const companyData = companyWithMarkets.company;
					const marketData = companyWithMarkets.markets;
					// Mapear os dados das lojas, se existirem
					const markets = marketData ? marketData.map((market: any) => ({
						id: market.ID,
						name: market.Name,
					})) : [];
					// Adicionar a empresa e os mercados ao array companies
					companies.push({
						company: {
							id: companyData.ID,
							name: companyData.Name,
						},
						markets: markets,
					});
				});
				setMarketList(companies);
			}
		})();

		if (lineID) {
			stateDispatch({ type: "removeElement", id: lineID });
			setLineID('')
		}
	}, [isOpen])

	useEffect(() => {
		if (isOpen && templateId) {
			editor.viewMode = "view";
			const request = api.request(member, { templateID: templateId });
			api.post(request, 'g').then((response) => {
				if (response.success) {
					const { template } = response.data;
					setTemplateOwnerID(template.OwnerID);
				}
			})
		}
	}, [isOpen, templateId]);

	useEffect(() => {
		if (isOpen && isLoginModalVisible) toggleSidebar();
	}, [isOpen, isLoginModalVisible])

	const handleOverlayClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		toggleSidebar();
	};

	useEffect(() => {
		if (!state.stageRef) {
			stateDispatch({ type: "setStageRef", stage: stageRef });
			if (SystemInfo.isDev && SystemInfo.DEBUG_MODE) console.log('canvas is ready');
		}
	}, [state.stageRef, templateId])

	// Permite remove seleção ao clica fora do Canvas.
	useEffect(() => {
		if (wrapperRef.current) {
			const checkSelection = (event: any) => {
				const targetID = event.target.id;
				// @TODO: unselect não está funcionando
				if (targetID === 'canvas-helper') stateDispatch({ type: "selectShape", payload: { id: "background", type: "background" } });
				// if (targetID === 'canvas-helper') unselect();
			}
			const ref = wrapperRef.current;
			ref.addEventListener('click', checkSelection);
			return () => ref.removeEventListener('click', checkSelection);
		}
	}, []);

	useEffect(() => {
		if (!isOpen) {
			setSelectedConstraint([]);
		}
	}, [isOpen]);

	const handleViewMode = (value: boolean) => {
		stateDispatch({ type: "changeViewMode", viewMode: value ? "edit" : "view" })
	}

	const canEditModel = (member.can('create_banner') && member.companyID === templateOwnerID) || member.admin();
	const imageURL = '/images/interface/ballsCustom.svg'

	const createLine = () => {
		const retangle = ComponentProvider.rectangle();
		retangle.x = 0;
		retangle.y = 297.2;
		retangle.width = 420;
		retangle.height = 1;
		retangle.type = "background";
		retangle.fill = "#000";
		setLineID(retangle.id);
		return retangle;
	}

	const handleSplitCanvasLine = () => {
		if (!lineID) {
			const lineCanvas = createLine();
			stateDispatch({ type: "addElement", attributes: lineCanvas });
		} else {
			stateDispatch({ type: "removeElement", id: lineID });
			setLineID('');
		}
	};


	const handleAddToQueueCurrentTemplate = () => {
		const product = bannerProducts.current?.[0];

		if (!product) {
			Notify.Error("Selecione um produto antes de gerar um cartaz.");
			return;
		}

		addToQueue(product.item);
		Notify.Success("Adicionado à fila!");
	}


	return (
		<React.Fragment>
			<DynamicTextModal />

			<div
				className={`fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-500 ease-in-out z-40 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
				onClick={handleOverlayClick}>
			</div>

			<div
				className={`fixed top-0 right-0 h-screen z-50 bg-zinc-100 dark:bg-slate-700 ${isLargeScreen ? `${"w-[90%] xl:w-[84%]"}` : 'w-[100%]'} min-w-[250px] transition-all duration-300 ease-in-out ${isOpen ? 'transform-none opacity-100 pointer-events-auto' : 'transform translate-x-full opacity-0 pointer-events-none'}`}
				style={{ backgroundImage: `url(${imageURL})` }}
			>
				<section className="flex flex-col h-full w-full">

					<div className={"flex flex-col items-center h-full w-full overflow-auto pr-2"}>
						<div className='flex flex-row space-x-2 h-full w-full justify-around overflow-hidden'>
							{
								!isSmallScreen && (
									<div className='max-h-full w-3/5 overflow-auto'>
										<EditorSidebar />
									</div>
								)
							}
							<div id="canvas-helper" ref={wrapperRef} className={`relative flex flex-col justify-center items-center h-full w-full p-2 ${paddingTop}`} >
								<div className="absolute top-0 left-0 w-full">
									<div className="w-full">
										<EditorTopBar />
									</div>
									{!isSmallScreen && <StageToolbar />}
								</div>
								<div className="h-fit w-fit bg-white border border-black">
									<Canvas />
								</div>
								<div className={`absolute bottom-4 right-0 w-full flex justify-between items-center h-8 space-x-4`}>
									{
										state.editor.orientation === "vertical" ? (
											<MouseOverPopover popoverText='O documento A3 será separado em duas folhas A4 a partir da marca divisória.'>
												<button
													onClick={handleSplitCanvasLine}
													className={`m-1 flex items-center justify-center bg-white rounded-lg hover:bg-zinc-200 duration-500 p-2 cursor-pointer`}>
													<SpliterIcon />
												</button>
											</MouseOverPopover>
										) : (<div></div>)
									}
									<div className="flex gap-2 items-center">
										{
											current.exists && current.type && ['star', 'polygon'].includes(current.type) && <SidesController />
										}
										{
											current.exists && current.type && ['rectangle'].includes(current.type) && <BorderRadiusController />
										}
										{
											member.can("edit_banner_texts") && (
												<div className={`max-w-20 pb-4 ${canEditModel ? 'visible' : 'invisible'}`}>
													<BooleanInputRowWrapper label="Editar"
														defaultValue={editor.viewMode === 'edit'}
														onChange={handleViewMode}
														state={editor.viewMode}
													/>
												</div>
											)
										}
										<button disabled={isGeneratingImage} className={`p-3 h-fit rounded-lg text-white text-center ${isGeneratingImage ? 'bg-gray-500 cursor-not-allowed' : `${__BG_ANIMATED_GRADIENT__} cursor-pointer`}`}
											onClick={handleAddToQueueCurrentTemplate}
										>
											Adicionar à Fila
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</React.Fragment>
	);
};

export default CanvasSidebar;

function calculatePaddingTop(orientation: Orientation): string {
	switch (orientation) {
		case 'horizontal':
		case 'post': return 'p-32';
		case 'story':
		case 'vertical':
		default: return 'p-5';
	}
}
