import React, { useEffect, useState } from 'react';
import { ViewMode } from '@/types/canvas';
import BooleanInputRow, { BooleanInputRowInterface } from '@/components/inputs/rows/BooleanInputRow';

interface BooleanInputWrapperInterface extends BooleanInputRowInterface {
  state: ViewMode;
}

const BooleanInputRowWrapper: React.FC<BooleanInputWrapperInterface> = ({ state, ...props }) => {
  const [_, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey(prevKey => prevKey + 1);
  }, [state]);

  return <BooleanInputRow key={_} {...props} />;
};

export default BooleanInputRowWrapper;
