interface ISquareICon {
    className?: string;
}

const SquareICon: React.FC<ISquareICon> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
        >
            <rect
                width="18"
                height="18"
                x="3"
                y="3"
                rx="2"
            />
        </svg>
    )
}

export default SquareICon;