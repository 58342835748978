import React, { useEffect } from 'react';
import IconButton from './IconButton';
import ComponentProvider from '../ComponentProvider';
import { freeSet } from '@coreui/icons';
import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import { ElementData, ElementType } from '@/types/canvas';
import { useTranslation } from 'react-i18next';
import SquareICon from '@/components/svg/SquareIcon';
import TriangleICon from '@/components/svg/TriangleIcon';
import ImageIcon from '@/components/svg/ImageIcon'
import PentagonIcon from '@/components/svg/PentagonIcon';
import CircleIcon from '@/components/svg/CircleIcon';
import EmptyStart from '@/components/svg/EmptyStar';
import TextIcon from '@/components/svg/TextIcon';
import TrashIcon from '@/components/svg/TrashIcon';

interface ElementButtonsInterface {
    absolute?: boolean;
    col?: boolean
}

const ElementButtons: React.FC<ElementButtonsInterface> = ({ absolute, col = false }) => {
    const { t: c } = useTranslation('canvas.translation');

    const {
        state,
        removeStageZoom,
        getElementDataById,
    } = useCanvas();

    const { stateDispatch } = useCanvasDispatch();

    const { editor, elements, current, products, hovering } = state;
    const isEditing = editor.viewMode === "edit";
    const isBackground = current.id === "background";

    /**
     * Adiciona o elemento ao canvas,
     * marca como elemento atual e
     * atualiza a sessão caso necessário.
     * 
     * @param type ElementType
     */
    const handleAddElement = (type: ElementType) => {
        const newElement = createElement(type);
        stateDispatch({ type: 'addElement', attributes: newElement });
        stateDispatch({ type: "selectShape", payload: { id: newElement.id, type: newElement.type } });
        switch (type) {
            case 'text':
            case 'pricing':
                stateDispatch({ type: 'updateSection', section: 'texts' });
                break;
            case 'image':
                stateDispatch({ type: "updateSection", section: 'images' });
                break;
        }
    };

    const moveTo = (target: 'front' | 'back') => {
        const data = getElementDataById(current.id);
        if (current.id && data && current.id !== "background") {
            const currentIndex = elements.findIndex(currentData => currentData.attributes.id === current.id);
            const backgroundIndex = elements.findIndex(currentData => currentData.attributes.id === "background");
            let updatedElements: ElementData[] = elements.slice();

            if (target === 'front' && currentIndex < elements.length - 1) {
                const targetIndex = currentIndex + 1 === elements.length - 1 ? elements.length - 1 : currentIndex + 1;
                [updatedElements[currentIndex], updatedElements[targetIndex]] = [updatedElements[targetIndex], updatedElements[currentIndex]];
            }
            else if (target === 'back' && currentIndex > 1) {
                const targetIndex = currentIndex - 1 === backgroundIndex ? backgroundIndex : currentIndex - 1;
                [updatedElements[currentIndex], updatedElements[targetIndex]] = [updatedElements[targetIndex], updatedElements[currentIndex]];
            }
            else {
                return;
            }

            if (backgroundIndex !== 0) {
                updatedElements.splice(backgroundIndex, 1);
                updatedElements.unshift(elements[backgroundIndex]);
            }

            updatedElements.forEach((_, index) => updatedElements[index].zIndex = index + 1);
            stateDispatch({
                type: "injectElements",
                elements: updatedElements,
                products: products
            });
        }
    };

    const handleDuplicate = () => {
        if (isBackground) return;
        stateDispatch({ type: "duplicateElement", id: current.id });
    }

    const handleReplacement = () => {
        if (!current.id) return;
        const image = ComponentProvider.image();
        stateDispatch({ type: "replaceElement", id: current.id, attributes: image });
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Delete') {
                if (isEditing) {
                    event.preventDefault();
                    stateDispatch({ type: "removeElement", id: current.id });
                }
            }
            if (event.key === 'c' && event.ctrlKey) {
                if (isEditing && hovering) {
                    event.preventDefault();
                    handleDuplicate();
                }
            }

        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [current, hovering]);

    return (
        <React.Fragment>
            {
                <div className={`${absolute && 'absolute'} grid grid-cols-2 ${col && 'flex-col'} w-full max-w-[6rem] justify-center items-center`}>
                    {
                        isEditing && (
                            // Elementos base.
                            <React.Fragment>
                                <IconButton title={c("editor.shapes.text")} content={<TextIcon className='fill-zinc-600' />} onClick={() => handleAddElement('text')} />
                                <IconButton title={c("editor.shapes.image")} content={<ImageIcon className='stroke-zinc-600' />} onClick={() => handleAddElement('image')} />
                                <IconButton title={c("editor.shapes.rectangle")} content={<SquareICon className='stroke-zinc-600' />} onClick={() => handleAddElement('rectangle')} />
                                <IconButton title={c("editor.shapes.triangle")} content={<TriangleICon className='stroke-zinc-600' />} onClick={() => handleAddElement('triangle')} />
                                <IconButton title={c("editor.shapes.circle")} content={<CircleIcon className='stroke-zinc-600' />} onClick={() => handleAddElement('circle')} />
                                <IconButton title={'Polígono'} content={<PentagonIcon className='stroke-zinc-600' />} onClick={() => handleAddElement('polygon')} />
                                <IconButton title={'Estrela'} content={<EmptyStart className='stroke-zinc-600' />} onClick={() => handleAddElement('star')} />
                                {/* <IconButton title={'Substituir'} icon={freeSet.cilAsterisk} onClick={handleReplacement} /> */}
                            </React.Fragment>
                        )
                    }

                    {
                        current.id && !isBackground && isEditing && (
                            // Manipulação de elementos.
                            <React.Fragment>
                                <IconButton title={'Clonar'} icon={freeSet.cilClone} onClick={handleDuplicate} />
                                <IconButton title={'Subir'} icon={freeSet.cilFlipToFront} onClick={() => moveTo('front')} />
                                <IconButton title={'Descer'} icon={freeSet.cilFlipToBack} onClick={() => moveTo('back')} />
                                <IconButton title={'Remover elemento'} content={<TrashIcon className='stroke-zinc-600' />} onClick={() => stateDispatch({ type: "removeElement", id: current.id })} />
                            </React.Fragment>
                        )
                    }

                    {
                        // Zoom button.
                        editor.isZoomEnabled && <IconButton title={'Remover Zoom'} icon={freeSet.cilFullscreenExit} onClick={removeStageZoom} isActive={editor.isZoomEnabled} />
                    }
                </div>
            }
        </React.Fragment>
    )
}

export default ElementButtons;

function createElement(type: ElementType) {
    switch (type) {
        case 'rectangle':
            return ComponentProvider.rectangle();
        case 'triangle':
            return ComponentProvider.triangle();
        case 'circle':
            return ComponentProvider.circle();
        case "image":
            return ComponentProvider.image({ image: '/images/no-img.png' });
        case 'text':
            return ComponentProvider.text({ text: 'Novo Texto' });
        case 'pricing':
            return ComponentProvider.pricing();
        case 'star':
            return ComponentProvider.star();
        case 'polygon':
            return ComponentProvider.polygon();
        default:
            throw new Error(`Type '${type}' is not supported`);
    }
}
