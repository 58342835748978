interface IImageIcon {
    className?: string;
}

const ImageIcon: React.FC<IImageIcon> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
        >
            <rect
                width="18"
                height="18"
                x="3"
                y="3"
                rx="2"
                ry="2"
            />
            <circle
                cx="9"
                cy="9"
                r="2"
            />
            <path
                d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
        </svg>
    )
}

export default ImageIcon;