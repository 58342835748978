import { useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import React from 'react';

interface ArrowProps {
    onClick: () => void
}

const BorderRadiusController: React.FC = () => {
    const { state, getElementDataById } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();
    const { current } = state;

    const data = getElementDataById(current.id);
    const element = data?.attributes;

    const increaseCorner = () => {
        if (element) {
            const increase = element.cornerRadius + 2;
            if (increase <= 30) {
                stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: element.id,
                        attributes: {
                            ...element,
                            cornerRadius: increase
                        }
                    }
                })
            }
        }
    }

    const decreaseCorner = () => {
        if (element && element.cornerRadius >= 2) {
            const decrease = element.cornerRadius - 2;
            if (decrease >= 0) {
                stateDispatch({
                    type: "updateElement",
                    payload: {
                        id: element.id,
                        attributes: {
                            ...element,
                            cornerRadius: decrease
                        }
                    }
                })
            }
        }
    }

    return (
        <div className={`p-2 bg-white shadow-md rounded-lg flex items-center no-text-highlight`}>
            <div className="mr-2">
                <svg className="stroke-0" xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                    <path d="M0 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-336c0-8.8 7.2-16 16-16l336 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32C35.8 32 0 67.8 0 112L0 448zm160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm192 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-96 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm192 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM416 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm0 32a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-128a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
                </svg>
            </div>
            <div className="mr-2">
                {element?.cornerRadius || 0}
            </div>
            <div className="flex flex-row gap-2">
                <ArrowUp onClick={increaseCorner} />
                <ArrowDown onClick={decreaseCorner} />
            </div>
        </div>
    )
}

export default BorderRadiusController;

const ArrowUp: React.FC<ArrowProps> = ({ onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={onClick}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
    </svg>
);

const ArrowDown: React.FC<ArrowProps> = ({ onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        onClick={onClick}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
    </svg>
);
